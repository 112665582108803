import {AdData} from 'src/apps/ads/types';


export const vehicleSearchAds: Array<AdData> = [
  {
    name: 'Horbach',
    img: require('assets/ads/horbach/horbach_quadratisch_w850.webp'),
    link: 'https://www.wohnmobile-versichern.de/?utm_source=campertrader&utm_medium=kachel&utm_campaign=campertrader-partner',
    title: 'Horbach - Camper Versichern',
    shortText: 'Schnell & einfach deine Camper-Versicherung berechnen',
    fit: 'contain',
    multiplier: 30,
  },
  {
    name: 'travel tiger',
    img: require('assets/ads/travel_tiger/travel_tiger_advert.webp'),
    link: 'https://www.travel-tiger.com/?utm_source=campertrader.de/suche?t=vehicle&utm_medium=coop&utm_campaign=bundleq1&utm_content=ad',
    title: 'travel tiger Campingmodule ',
    shortText: 'Entdecke Bett & Küche für dein Auto oder Van!',
    fit: 'contain',
    multiplier: 30,
  },
  {
    name: 'Reimo',
    img: require('assets/ads/reimo/reimo_ad.jpg'),
    link: 'https://www.reimo.com/?etcc_cmp=Campertrader&etcc_med=Banner',
    title: 'Reimo - Camping und Freizeit',
    shortText: 'Campingzubehör, Ausbau Material & Ersatzteile für Wohnmobil, Campingbus, Wohnwagen',
    fit: 'contain',
    multiplier: 15,
  },
  {
    name: 'Boxio',
    img: require('assets/ads/boxio/boxio_ad_scaled.jpg'),
    link: 'https://boxio.de/products/boxio-toilet?utm_source=website&utm_medium=influencer&utm_campaign=campertrader&utm_term=campertrader&utm_content=banner',
    title: '10% Rabatt - Hole dir deine Boxio Toilette',
    shortText: 'Die perfekte Lösung für alle, die auf Reisen nicht auf Komfort verzichten wollen.',
    fit: 'cover',
    multiplier: 15,
  },
  {
    name: 'Plattenladen',
    img: require('assets/ads/plattenladen/plattenladen.webp'),
    link: 'https://www.platten-laden.com/?utm_source=anzeige&utm_medium=website&utm_campaign=campertrader',
    title: 'Plattenladen - Dein Shop für Holz und mehr',
    shortText: 'Entdecke die Vielfalt an Holzwerkstoffen!',
    fit: 'cover',
    multiplier: 15,
  },
  {
    name: 'Camp\'n\'Connect',
    img: require('assets/ads/campnconnect/campnconnect.webp'),
    link: 'https://onelink.to/nnyf5t',
    title: 'Camp\'n\'Connect - Deine Camper Community',
    shortText: 'Alle deine Camperfreunde und die besten Camping-Events sind nur einen Klick entfernt!',
    fit: 'cover',
  },
  {
    name: 'Campers Compass',
    img: require('assets/ads/camperscompass/camperscompass.webp'),
    link: 'https://www.campers-compass.com/de?utm_source=campertrader&utm_medium=ad',
    title: 'Wir planen individuelle Campingreisen',
    shortText: 'Das Rundum-Sorglos-Paket für deinen Trip!',
    fit: 'cover',
  },
]

export const marketSearchAds: Array<AdData> = [
  {
    name: 'Kildwick',
    img: require('assets/ads/kildwick/kildwick_ad.webp'),
    link: 'https://www.kildwick.com/?utm_source=campertrader&utm_medium=ad&utm_campaign=campertrader',
    title: 'Kildwick - Trockentrenntoiletten',
    shortText: 'Jetzt 10% sparen! Code: CampKLDWCK',
    fit: 'cover',
  },
  {
    name: 'travel tiger',
    img: require('assets/ads/travel_tiger/travel_tiger_market_1.webp'),
    link: 'https://www.travel-tiger.com/pages/campingbett-schlofe?utm_source=campertrader.de ',
    title: 'travel tiger Campingmodule',
    shortText: 'In 5 Minuten & ohne Werkzeug aufgebaut',
    fit: 'cover',
    extra: {
      categories: [172, 173, 176, 177, 174, 175],
    }
  },
  {
    name: 'travel tiger',
    img: require('assets/ads/travel_tiger/travel_tiger_market_2.webp'),
    link: 'https://www.travel-tiger.com/pages/campingkueche-koche?utm_source=campertrader.de',
    title: 'travel tiger Campingmodule',
    shortText: 'Blitzschnell & ohne Werkzeug aufgebaut',
    fit: 'cover',
    extra: {
      categories: [114, 120, 127],
    }
  }
]
