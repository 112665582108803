import {Extra, VehicleExtra} from 'src/models/vehicle';
import {Advert} from 'src/models/advert';
import Adverts from 'src/api/adverts';
import Market from 'src/api/market';
import {Dialog, Loading, LocalStorage, QSpinnerGears} from 'quasar';
import {api} from 'boot/axios';
import {getMessaging, getToken} from 'firebase/messaging';
import {firebase} from 'boot/firebase';
import {formatDistance} from 'date-fns'

import {useMainStore} from 'stores/main';
import {de} from 'date-fns/locale';
import {useSubscriptionStore} from 'src/apps/subscription/store';
import LoginSignupDialog from 'src/apps/auth/dialogs/LoginSignupDialog.vue';
import {RouteRecordRaw} from 'vue-router';
import {Store} from 'pinia';
import {CapacitorApp, CapacitorBrowser, CapacitorCore, CapacitorPreferences} from 'boot/inject-capacitor';
import webRoutes from 'src/router/routes';


const mapping = [
  {
    id: 'electric',
    name: 'Elektrik',
    extraSlugs: ['battery', 'generator', 'inverter', 'booster', 'solar', 'shore_power_socket'],
  },
  {
    id: 'sanitary',
    name: 'Sanitär',
    extraSlugs: ['shower', 'toilet', 'water_tank', 'greywater_drain', 'hot_water', 'heater', 'clean_water_socket', 'outdoor_shower', 'water_filter_system']
  },
  {
    id: 'kitchen',
    name: 'Küche',
    extraSlugs: ['oven', 'stove', 'sink', 'microwave', 'fridge', 'table']
  },
  {
    id: 'entertainment',
    name: 'Entertainment',
    extraSlugs: ['television', 'satellite_dish', 'sound_system']
  },
  {
    id: 'security',
    name: 'Sicherheit',
    extraSlugs: ['fire_extinguisher', 'first_aid_kit', 'safety_box', 'alarm_system']
  }
]

const figuresMapping = {
  'battery': ['battery_capacity_in_ah'],
  'inverter': ['inverter_in_w'],
  'solar': ['solar_in_w'],
  'gas_tank': ['gas_vol_in_l'],
  'hot_water': ['boiler_vol_in_l'],
  'water_tank': ['clean_water_tank_in_l', 'greywater_tank_in_l'],
}

const figureDimensionsMapping = {
  'battery_capacity_in_ah': 'Ah',
  'inverter_in_w': 'W',
  'solar_in_w': 'W',
  'gas_vol_in_l': 'l',
  'boiler_vol_in_l': 'l',
  'clean_water_tank_in_l': 'l | Frisch ',
  'greywater_tank_in_l': 'l | Grau',
}

export function extraMapping(extras: Array<VehicleExtra | Extra> | undefined, customMapping: Array<object> | undefined = undefined) {
  if (!extras) return []
  const _mapping = customMapping || mapping
  const _processed_slugs: Array<string> = []
  const result: Array<object> = []

  _mapping.forEach(mappingObj => {
    const _filtered_extras = extras.filter(
      obj => {
        const slug = obj['value'] || obj['slug']
        return mappingObj['extraSlugs'].includes(slug)
      }
    )

    _filtered_extras.map(obj => {
      const slug = obj['value'] || obj['slug']
      return slug ? _processed_slugs.push(slug) : undefined
    })
    result.push({
      name: mappingObj['name'],
      id: mappingObj['id'],
      extras: _filtered_extras,
    })
  })


  return [
    ...result,
    {
      name: 'Weitere',
      extras: extras.filter(obj => {
        const slug = obj['value'] || obj['slug']
        return !_processed_slugs.includes(slug)
      })
    }
  ]
}

export function getExtraFigures(extraSlug: string, advert: Advert): Array<object> | undefined {
  if (!Object.keys(figuresMapping).includes(extraSlug) || !advert) return
  const names = figuresMapping[extraSlug]
  const r: Array<object> = []
  names.forEach(fieldName => {
    if (advert.vehicle.figures[fieldName]) {
      r.push({
        field: fieldName,
        value: advert.vehicle.figures[fieldName],
        dimension: figureDimensionsMapping[fieldName]
      })
    }
  })
  return r
}

export function getPageUrl(pageName, router) {
  try {
    return `${process.env.BASE_URL}${router.resolve({name: pageName}).fullPath}`
  } catch (err) {
    console.error(err)
    return
  }
}

export const getWidgetIFrameUrl = function (uuid, router) {
  const path = router.resolve(
    {name: 'widget-advert-list', params: {widgetUuid: uuid}}
  ).fullPath
  const baseUrl = process.env.BASE_URL
  if (!baseUrl) return ''
  return new URL(path, baseUrl).href
}

export const getWidgetIFrame = function (uuid, router) {
  const url = getWidgetIFrameUrl(uuid, router)
  return `<iframe src="${url}" style="border: none; margin: 0; width: 100%; display: block; height: 100vh"></iframe>`
}

export const randomIntFromInterval = function (min, max) { // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min)
}

export const humanizeDate = (dateVal) => {
  if (!dateVal) return
  const date = new Date(dateVal)
  try {
    return formatDistance(date, new Date(), {addSuffix: true, locale: de})
  } catch (e) {
    console.error(e)
  }
}

export const humanizeSeconds = function (seconds: number) {
  const days = Math.floor(seconds / 86400);
  seconds %= 86400;
  const hours = Math.floor(seconds / 3600);
  seconds %= 3600;
  const minutes = Math.floor(seconds / 60);
  seconds %= 60;

  if (days > 0) {
    if (hours > 0) {
      return `${days}d ${hours}h`;
    } else if (minutes > 0) {
      return `${days}d ${minutes}min`;
    } else {
      return `${days}d ${seconds}s`;
    }
  } else if (hours > 0) {
    if (minutes > 0) {
      return `${hours}h ${minutes}min`;
    } else {
      return `${hours}h ${seconds}s`;
    }
  } else {
    return `${minutes}min ${seconds}s`;
  }
}


export function showLoginDialog(showRequired = true) {
  Dialog.create({
    component: LoginSignupDialog,
    componentProps: {
      showRequired
    }
  })
}


export const toggleAdvertFavorite = (advert) => {
  return Adverts.toggleUserFavorite(advert.uuid).then(resp => {
    advert.is_favorite = resp.status === 201
    return resp
  }).catch(err => {
    if (err.response.status === 401) {
      showLoginDialog()
      return
    }
    return err
  })
}


export const toggleItemFavorite = (advert) => {
  return Market.toggleUserFavorite(advert.uuid).then(resp => {
    advert.is_favorite = resp.status === 201
    return resp
  }).catch(err => {
    if (err.response.status === 401) {
      showLoginDialog()
      return
    }
    return err
  })
}


export function registerDevice(messaging: any = undefined) {
  if (!navigator?.serviceWorker) {
    console.debug('navigator and/or serviceWorker not defined')
    return
  }

  const mainStore = useMainStore()
  let _messaging = messaging
  if (!messaging) {
    try {
      _messaging = getMessaging(firebase)
    } catch (err) {
      console.error(err)
      return
    }
  }

  return getToken(_messaging, {vapidKey: process.env.FCM_VAPID_KEY}).then(token => {
    console.debug('firebase token', token)
    const data = {
      registration_id: token.toString(),
      active: true,
      type: 'web',
      device_id: mainStore.deviceId,
    }
    if (mainStore.accountDetail) {
      api.post('devices/', data).then(() => {
        console.debug('Notifications granted')
      }).catch((err) => {
        console.error(err)
      })
    }
    return token
  }).catch((err) => {
    console.error(err)
    return err
  })
}

const mapExtraFigures = {
  'solar': {
    key: 'vehicle__figures__solar_in_w',
    unit: 'W',
  },
  'water_tank': {
    key: 'vehicle__figures__clean_water_tank_in_l',
    unit: 'l',
  },
  'inverter': {
    key: 'vehicle__figures__inverter_in_w',
    unit: 'W',
  },
  'battery': {
    key: 'vehicle__figures__battery_capacity_in_ah',
    unit: 'Ah',
  },
  'gas_tank': {
    key: 'vehicle__figures__gas_vol_in_l',
    unit: 'l',
  },
  'hot_water': {
    key: 'vehicle__figures__boiler_vol_in_l',
    unit: 'l',
  },

}
export const getChipLabelExtraFigureKeys = function (key) {
  if (!mapExtraFigures.hasOwnProperty(key)) return
  const fig = mapExtraFigures[key]

  return {
    minKey: `${fig.key}_min`,
    maxKey: `${fig.key}_max`,
    unit: fig.unit
  }
}


export const setBusinessAccount = async function (slug: string | null, router: any = undefined) {
  const mainStore = useMainStore()
  const subStore = useSubscriptionStore()
  const isChange = mainStore.accountDetail?.active_account?.slug !== slug

  if (isChange) {
    if (slug) {
      try {
        await mainStore.fetchBusiness(slug)
      } catch (err) {
        const {response} = err as any

        if ([404, 400].includes(response?.status)) {
          router.replace({name: '404'});
          return
        }
      }
    }

    Loading.show({
      message: 'Profil wird gewechselt ...',
      spinner: QSpinnerGears
    })
    await mainStore.setActiveAccount(slug)
    subStore.fetchAccountSubscription()
    subStore.fetchAccountQuota()
  }

  if (router) {
    router.push(getAccountPageParams())
  }
  Loading.hide()
}


export function getAccountPageParams(mainStoreInstance: Store | undefined = undefined): object | string {
  let mainStore: any = mainStoreInstance
  if (!mainStoreInstance) {
    mainStore = useMainStore()
  }
  if (!mainStore.accountDetail?.active_account) return '/'
  if (mainStore.accountDetail?.active_account.is_business) {
    return {name: 'business-account', params: {slug: mainStore.accountDetail?.active_account?.slug}}
  } else {
    return {name: 'account'}
  }
}


export function limitReachedRoute(limitType: string) {
  const mainStore = useMainStore()
  if (mainStore.accountDetail?.active_account.is_business) {
    return {name: 'pricing-commercial-use-tab', query: {limit: limitType}}
  }
  return {name: 'limit-reached', query: {limit: limitType}}
}


export function updateParams(route, router, newParams, merge = true) {
  // Create a copy of the existing query parameters
  let updatedParams = {}
  if (merge) {
    updatedParams = {...route.query, ...newParams};
  } else {
    updatedParams = newParams
  }
  // Use the router to navigate to the same route with new parameters
  router.replace({name: route.name?.toString(), query: updatedParams});
}


export function extractAllPaths(router): Array<string> {
  // Initialize an empty array to hold all paths
  const paths: Array<string> = [];

  // Nested function to recursively add paths from routes and their children
  function addPaths(routes: RouteRecordRaw[]) {
    routes.forEach(route => {
      if (route?.path) {
        paths.push(route.path);  // Add the path to the paths array
      }
      if (route?.children) {
        addPaths(route.children);  // Recursively add paths from children
      }
    });
  }

  // Start adding paths from the root routes
  addPaths(router.getRoutes().filter(obj => obj.aliasOf === undefined));
  return paths;  // Return the collected paths
}


export async function reloadApp() {
  let isCapactior
  try {
    const {Capacitor} = await import('@capacitor/core')
    isCapactior = Capacitor.isNativePlatform()
  } catch (e) {
    console.error(e)
  }
  if (isCapactior) {
    document.location.href = 'index.html';
  } else if (typeof window !== undefined) {
    window.location.reload()
  }
}


export function isSubset(dict1: Record<string, any>, dict2: Record<string, any>): boolean {
  for (const key in dict1) {
    if (!dict2.hasOwnProperty(key)) {
      return false;
    }

    if (Array.isArray(dict1[key])) {
      // Check if every item in the dict1 array is included in the dict2 array
      if (!Array.isArray(dict2[key]) || !dict1[key].every(item => dict2[key].includes(item))) {
        return false;
      }
    } else if (typeof dict1[key] === 'object' && dict1[key] !== null) {
      // Recursive check for nested objects
      if (!isSubset(dict1[key], dict2[key])) {
        return false;
      }
    } else {
      // Check for primitive value equality
      if (dict1[key] !== dict2[key]) {
        return false;
      }
    }
  }
  return true;
}


export function randomString(length: number) {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}


export function appUrlOpenListener($q, router) {
  // Early return if the platform is not native mobile or CapacitorApp is not available
  if (!$q.platform.is.nativeMobile || !CapacitorApp) return;

  const ERROR_ROUTES = ['not-found', '404', 'error'];
  const EXCLUDED_ROUTE_NAMES: string[] = []; // Example excluded route names
  const EXCLUDED_ROUTE_PATHS: string[] = ['/blog']; // will open in browser

  function isPathExcluded(path: string) {
    return EXCLUDED_ROUTE_PATHS.some((excludedPath) =>
      path.includes(excludedPath)
    )
  }

  /**
   * Helper function to validate a route.
   * Returns true if the route exists, is not an error route, and is not excluded.
   */
  function isValidRoute(router, path) {
    try {
      const resolvedRoute = router.resolve(path);

      console.info('TEST', path, resolvedRoute.fullPath.toString(), isPathExcluded)

      return (
        resolvedRoute.matched.length > 0 &&
        !ERROR_ROUTES.includes(resolvedRoute.name?.toString()) &&
        !EXCLUDED_ROUTE_NAMES.includes(resolvedRoute.name?.toString()) &&
        !isPathExcluded(path)
      );
    } catch (e: any) {
      console.error('Error in isValidRoute:', e.message || e);
      return false;
    }
  }

  /**
   * Convert a route path to a regex.
   * Replaces dynamic segments with a capturing group.
   */
  function pathToRegex(path: string): RegExp {
    return new RegExp(
      `^${path.replace(/:[^\s/]+/g, '([^/]+)').replace(/\//g, '\\/')}$`
    );
  }

  /**
   * Match a path to a route in the router's configuration.
   * Recursively checks for matching children routes.
   */
  function matchRoute(path: string, routes: RouteRecordRaw[]): RouteRecordRaw | null {
    for (const route of routes) {
      if (route.name && ERROR_ROUTES.includes(route.name.toString())) {
        continue; // Skip error routes
      }

      const routeRegex = pathToRegex(route.path);

      if (routeRegex.test(path)) {
        return route;
      }

      // Recursively check children routes
      if (route.children && route.children.length > 0) {
        const matchedChild = matchRoute(path, route.children);
        if (matchedChild) {
          return matchedChild;
        }
      }
    }

    return null;
  }

  /**
   * Listener for app URL open events.
   * Handles deep links and external URLs.
   */
  CapacitorApp.addListener('appUrlOpen', function (event) {
    const {url: eventUrl} = event;

    // Handle campertrader.app domain specifically
    if (eventUrl.includes('campertrader.app')) {
      console.debug('[campertrader.app] Handling app domain navigation:', eventUrl);
      handleAppDomainNavigation(eventUrl);
      return;
    }

    // Handle OAuth URLs with custom protocol
    const sanitizedUrl = eventUrl.replace('://', ':/'); // Prevent double slashes issue in URL
    const url = new URL(sanitizedUrl);

    if (url.protocol === 'campertrader:' && url.pathname === '/oauth') {
      console.debug('[OAuth] Handling OAuth redirect:', sanitizedUrl);
      handleOAuthRedirect(url);
      return;
    }

    // Handle campertrader.de domain URLs
    const slug = eventUrl.split('campertrader.de').pop();

    if (isValidRoute(router, slug)) {
      console.debug('[campertrader.de] Valid route found, navigating to:', slug);
      router.push(slug);
    } else {
      console.debug('[campertrader.de] Route not found, checking exclusions and matching route:', slug);

      // Handle exclusions: Open in browser if the path is excluded
      if (isPathExcluded(slug)) {
        CapacitorBrowser.open({url: eventUrl});
        return;
      }

      const matchedRoute = matchRoute(slug, webRoutes);
      console.debug('[campertrader.de] Matched route:', matchedRoute?.name, matchedRoute?.path);

      if (matchedRoute) {
        CapacitorBrowser.open({url: eventUrl});
      } else {
        console.debug('[campertrader.de] No match found, redirecting to 404');
        router.push({name: '404'});
      }
    }
  });

  /**
   * Handles navigation for campertrader.app domain URLs.
   * Resolves the path and pushes the correct route to the router.
   */
  function handleAppDomainNavigation(eventUrl: string) {
    try {
      const url = new URL(eventUrl);
      const path = url.pathname;
      const searchParams = Object.fromEntries(url.searchParams.entries());

      const matchedRoute = router.resolve(path);

      if (
        matchedRoute.name &&
        !ERROR_ROUTES.includes(matchedRoute.name.toString()) &&
        !EXCLUDED_ROUTE_NAMES.includes(matchedRoute.name.toString()) &&
        !isPathExcluded(matchedRoute.path.toString())
      ) {
        router.push(searchParams ? {path, query: searchParams} : path);
      } else {
        console.debug('[campertrader.app] No valid route found, redirecting to 404');
        router.push({name: '404'});
      }
    } catch (e: any) {
      console.error('Error in handleAppDomainNavigation:', e.message || e);
      router.push({name: '404'});
    }
  }

  /**
   * Handles OAuth redirects with provider and query params.
   */
  function handleOAuthRedirect(url: URL) {
    try {
      const provider = url.searchParams.get('provider');
      const searchParams = Object.fromEntries(url.searchParams.entries());

      if (!provider) {
        throw new Error('OAuth provider is missing in URL');
      }

      router.push({
        name: 'social-auth',
        params: {provider},
        query: searchParams
      });
    } catch (e: any) {
      console.error('Error in handleOAuthRedirect:', e.message || e);
      router.push({name: '404'});
    }
  }
}


export function backBtnListener($route, $router, searchStore) {
  if (!CapacitorApp) {
    console.debug('backBtnListener: CapacitorApp not defined')
    return
  }

  CapacitorApp.addListener('backButton', function () {
    const isSearchPage = $route.name === 'search'
    if (window.history.length === 1 || isSearchPage) {
      if ($route.query?.cat) {
        $router.replace({name: 'search'})
        searchStore.setActiveCategory()
      } else {
        CapacitorApp.minimizeApp()
      }
    }
  })
}


/**
 * Stores data in native storage or local storage based on the platform. The data can optionally have a time-to-live (TTL) for expiration.
 *
 * @param {string} key - The key under which the data will be stored.
 * @param {*} value - The value to be stored.
 * @param {number | null} [ttl=null] - The time-to-live in seconds. If null, the data will not expire.
 * @return {Promise<boolean>} A promise that resolves to true if data was successfully stored, or false if an error occurred.
 */
export async function setStorageData(key: string, value, ttl: number | null = null): Promise<boolean> {
  try {
    const data = {
      value,
      expiry: ttl ? Date.now() + ttl * 1000 : null, // If ttl is null, no expiry is set
    }

    if (await CapacitorCore?.isNativePlatform()) {
      // Save to native storage
      await CapacitorPreferences.set({key, value: JSON.stringify(data)});
      console.debug(`Data saved to native storage with key: ${key}`, value);
      CapacitorPreferences.get({key}).then(({value}) => {
        console.debug(`Retrieved data from native storage with key: ${key}`, value);
      })
    } else {
      // Save to localStorage for web platform
      LocalStorage.setItem(key, data);
      console.debug(`Data saved to local storage with key: ${key}`);
    }

    return true; // Success
  } catch (error) {
    console.error(`Error saving data with key: ${key}`, error);
    return false; // Failure
  }
}


/**
 * Helper function to retrieve data from storage (native or web).
 */
export async function getStorageData(key: string): Promise<any> {
  let data;
  try {
    if (await CapacitorCore?.isNativePlatform()) {
      // Get from native storage
      const {value} = await CapacitorPreferences.get({key});
      data = JSON.parse(value) ?? null;
    } else {
      // Get from localStorage
      data = LocalStorage.getItem(key) ?? null;
    }

    if (!data) return null

    if (data.expiry !== null && Date.now() > data.expiry) {
      // Data has expired, remove it
      await removeStorageData(key);
      console.debug(`Data with key: ${key} has expired and was removed`);
      return null;
    }
    return data.value; // Return the stored value

  } catch (error) {
    console.error(`Error retrieving data with key: ${key}`, error);
    return null; // Fallback to null on failure
  }

}

/**
 * Helper function to remove data from storage (native or web).
 *
 * @param {string} key - The storage key.
 * @returns {Promise<boolean>} - Resolves true if the data is successfully removed, false otherwise.
 */
export async function removeStorageData(key) {
  try {
    if (await CapacitorCore?.isNativePlatform()) {
      // Remove from native storage
      await CapacitorPreferences.remove({key});
      console.debug(`Data removed from native storage with key: ${key}`);
    } else {
      // Remove from localStorage
      LocalStorage.removeItem(key);
      console.debug(`Data removed from local storage with key: ${key}`);
    }

    return true; // Success
  } catch (error) {
    console.error(`Error removing data with key: ${key}`, error);
    return false; // Failure
  }
}
