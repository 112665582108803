import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, renderSlot as _renderSlot, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = {
  class: "relative-position",
  style: {"aspect-ratio":"3/4"}
}
const _hoisted_2 = {
  key: 0,
  class: "absolute-full bg-grey-3"
}
const _hoisted_3 = { class: "absolute-center text-grey-6 text-center" }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { class: "absolute-top-right q-pa-sm text-white" }

import BookmarkBtn from 'src/apps/core/components/BookmarkBtn.vue';
import SrcsetImg from 'components/qutils/SrcsetImg.vue';
import {PropType} from 'vue';
import {Item} from 'src/models/market';
import CustomSwiperContainer from 'src/apps/core/components/CustomSwiperContainer.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'ItemCardImage',
  props: {
  data: {type: Object as PropType<Item>, required: true},
  hasSlider: {type: Boolean, default: false},
  target: {
    default: undefined,
    type: String
  },
  showOwner: {
    type: Boolean,
    default: true,
  },
  hideNavigation: {
    type: Boolean,
    default: true,
  },
},
  setup(__props) {





return (_ctx: any,_cache: any) => {
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_img = _resolveComponent("q-img")!

  return (_openBlock(), _createElementBlock("div", {
    class: "q-hoverable cursor-pointer overflow-hidden c-card rounded-borders bg-grey-3",
    onContextmenu: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["prevent"]))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _cache[2] || (_cache[2] = _createElementVNode("div", {
        class: "q-focus-helper",
        tabindex: "-1"
      }, null, -1)),
      (!__props.data?.thumbnails?.length || __props.data.thumbnails.length < 1)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_q_icon, {
                name: "image",
                size: "md"
              }),
              _cache[1] || (_cache[1] = _createElementVNode("div", { class: "text-xsmall" }, "Kein Bild", -1))
            ])
          ]))
        : (!__props.hasSlider || !__props.data?.thumbnails?.length || __props.data.thumbnails.length === 1)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createVNode(SrcsetImg, {
                "no-spinner": "",
                ratio: 3 / 4,
                "show-placeholder": true,
                src: __props.data?.cover_image?.image.path,
                fit: "cover",
                loading: "lazy"
              }, null, 8, ["src"])
            ]))
          : (_openBlock(), _createBlock(CustomSwiperContainer, {
              key: 2,
              navigation: !__props.hideNavigation,
              style: {"aspect-ratio":"3 / 4"},
              "pagination-dynamic-bullets": "true",
              "long-swipes": "true",
              "space-between": "0",
              "long-swipes-ratio": "0.25",
              "slides-data": __props.data?.thumbnails
            }, {
              default: _withCtx(({slideData, i}) => [
                _createVNode(_component_q_img, {
                  class: "rounded-borders",
                  "no-spinner": "",
                  ratio: 3 / 4,
                  src: slideData,
                  alt: __props.data?.seo_slug,
                  fit: "cover",
                  loading: i === 0 ? 'eager' : 'lazy',
                  fetchpriority: i === 0 ? 'high' : 'low'
                }, null, 8, ["src", "alt", "loading", "fetchpriority"])
              ]),
              _: 1
            }, 8, ["navigation", "slides-data"])),
      _createElementVNode("div", _hoisted_5, [
        _renderSlot(_ctx.$slots, "topRight", {}, () => [
          _createVNode(BookmarkBtn, {
            obj: __props.data,
            type: "item",
            style: {"z-index":"1"}
          }, null, 8, ["obj"])
        ])
      ])
    ])
  ], 32))
}
}

})